import React, { useEffect, useState } from "react";
import {H1, Dropdown, Input, Span} from "@dnb/eufemia"
import "@dnb/eufemia/style";
import Layout from "../components/layout";
import axios from 'axios';
import { CustomButton } from "../components/styledComponents";
import { Asset } from "../types/Asset";

const API = process.env.API_ENDPOINT
const window = global.window

const getAssets = () => {
    return fetch(`${API}/weatherforecast`)
      .then(data => data.json())
  }

interface CreatePurchaseOrderParams {
    buyThisAsset: string;
    amountToBuy: number;
    withThisAsset: string;
}

const createPurchaseOrder = async (params: CreatePurchaseOrderParams) => {
    const body = JSON.stringify({ 
      assetCode: params.buyThisAsset,
      amount: params.amountToBuy,
      usingAsset: params.withThisAsset,
    })
    return axios
        .post(`${API}/purchaseorder`, body, {
          headers: {
            "content-type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("access_token")}`
          },
        })
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          console.log('error in request:', err);
        })
        
  }

const Assets = [ "ETH", "BTC", "DOGE"  ]  

const Buy = () => {
    const queryString = (window && window.location) ? window.location.search : undefined;    
    const urlParams = new URLSearchParams(queryString);
    const assetParam = urlParams.get('asset')
    const [amount, setAmount] = useState<number>(0);
    const [asset, setAsset] = useState<Asset | undefined>(undefined);
    const [cost, setCost] = useState<number>(0);
    const [assetIndex, setAssetIndex] = useState<number>(-1);
    const [assets, setAssets] = useState<Array<Asset>>([]);

    useEffect(() => {
        getAssets()
          .then(items => {
            setAssets(items)            
          })
      }, [])

    useEffect(() => {
        if (assetParam) {
            const index = assets.findIndex((a) => a.code === assetParam)
            if (index != -1) {
                setAssetIndex(index);
                setAsset(assets[index]);
            } else {
                setAssetIndex(-1);
            }
        } else {
            setAssetIndex(-1);
        }
    }, [assetParam, assets])  

    useEffect(() => {
        if (asset) {    
            setCost(amount * asset.price.value);
        }
    }, [amount, asset, assetIndex])

    const handleAmountChange = (e) => {
        const evt = e as unknown as { value: string }
        const asNumber = parseFloat(evt.value || "0");
        setAmount(asNumber);
    }

    const handleBuyClick = () => {
        const s = {
            buyThisAsset: asset.code,
            amountToBuy: amount,
            withThisAsset: "NOK"
        };
        createPurchaseOrder(s);
    }

    const handleCurrencyChange = (e) => {
        const evt = e as unknown as { selected_item: number }
        setAsset(assets[evt.selected_item])
    }

    return <Layout>
        <H1>Buy</H1>
        <span>Buy </span>
        <Input onChange={handleAmountChange} /> 
        <Span> units of </Span>
        <Dropdown value={assetIndex} onChange={handleCurrencyChange}>
            { assets.map((item) => 
                <option key={item.code} value={item.code}>{item.name}</option>) }
        </Dropdown>
        <Span> for </Span>
        <Span>{cost} NOK </Span>
        <CustomButton onClick={handleBuyClick}>Buy Now</CustomButton>
    </Layout>
}

export default Buy;